<template lang="pug">
  v-card
    v-card-title Редактирование привязок
    v-card-text
      v-row
        v-col.pb-0(cols="3")
          p.subtitle-2.font-weight-medium( v-if="editItem.orgName") Организация
          p.subtitle-2.font-weight-medium( v-if="editItem.host") Хостнейм
          p.mb-0.subtitle-2.font-weight-medium Группа
        v-col.pb-0(cols="9")
          p( v-if="editItem.orgName") {{editItem.orgName}}
          p( v-if="editItem.host") {{editItem.host.name}}
          v-select.pt-0(
            v-model="selectedGroup"
            :items="groupList"
            item-value="id"
            item-text="name"
            :disabled="$root.isDisabled('notificatonsGroups')"
          )

      v-col.d-flex.justify-center
        v-btn.mr-3.text--secondary(
          text
          :disabled="loading"
          @click="$emit('close')"
        ) Отмена
        v-btn(
          :loading="loading"
          depressed
          color="primary"
          @click="save"
          :disabled="disableSubmit"
        ) Сохранить
</template>

<script>
import mixins from '@/utils/mixins';
import Validate from '@/validations/notificationsEdit';
import { mapActions } from 'vuex';

export default mixins(Validate).extend({
  props: {
    groupList: {
      type: Array,
      default: () => [],
    },
    editItem: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedGroup: null,
    };
  },

  mounted() {
    this.editItem.group && (this.selectedGroup = this.editItem.group.id);
  },

  computed: {
    disableSubmit() {
      return this.selectedGroup === this.editItem.group.id;
    },
  },

  methods: {
    ...mapActions('BINDINGS', ['editBinding']),

    async save() {
      this.$store.commit('BINDINGS/editItem', this.editItem);

      await this.editBinding(this.selectedGroup)
        .then(() => {
          this.$notify({
            group: 'note',
            type: 'info',
            title: `Изменения сохранены`,
            text: '',
          });
        })
        .catch(() => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: `Произошла ошибка сохранения привязки.`,
            text: '',
          });
        });
      this.$emit('update');
      this.$emit('close');
    },
  },
});
</script>
